import React from 'react';

import Layout from '../layouts/index';

const ImprintPage = props => (
  <Layout {...props}>
    <div className="container">
      <br />
      <br />
      <br />
      <h1>Impressum</h1>
      <p>
        Angaben gem&auml;&szlig; &sect; 5 TMG:
        <br />
        <br />
        M&ouml;hl GmbH &amp; Co. KG
        <br /> M&ouml;hlstra&szlig;e 24
        <br /> 51069 K&ouml;ln
        <br />
        Telefon:&nbsp;0221 / 68 10 66
        <br />
        Telefax: 0221 / 680 47 60
        <br />
        E-Mail: info [at] moehl-koeln.de
        <br />
        <br />
        Registergericht: Amtsgericht K&ouml;ln
        <br />
        Registernummer: HRA 801
        <br />
        <br />
        Pers&ouml;nlich haftende Gesellschafterin der M&ouml;hl GmbH &amp; Co.
        KG:
        <br />
        Dr. M&ouml;hl Verwaltungsgesellschaft mbH
        <br />
        Gesch&auml;ftsf&uuml;hrer der Dr. M&ouml;hl Verwaltungsgesellschaft mbH:
        <br /> Frederik M&ouml;hl
        <br />
        Registergericht: Amtsgericht K&ouml;ln
        <br /> Registernummer: HRB K&ouml;ln 33850
        <br />
        <br />
        Umsatzsteuer-ID:
        <br /> Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27a
        Umsatzsteuergesetz:
        <br /> DE164852356
        <br />
        <br />
        Quellenangaben f&uuml;r die verwendeten Bilder und Graphiken:
        <br /> Fotografin: Gesa Friederike Kuis
      </p>
      <p>
        Haftungsausschluss (Disclaimer)
        <br /> Haftung f&uuml;r Inhalte
        <br /> Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
        f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
        gespeicherte fremde Informationen zu &uuml;berwachen oder nach
        Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon
        unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen.
        <br /> Haftung f&uuml;r Links
        <br /> Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter,
        auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
        f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
        F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
        Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
        wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
        Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        Urheberrecht
        <br /> Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
        diesen Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen
        der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
        kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
        nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
        bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  </Layout>
);

export default ImprintPage;
